import React from 'react';
import './Header.css';



function Header() {
  return (
    <>
      <h1 className='main-header'>Dicky Kitchen Jr</h1>
    </>
  );
}

export default Header
